form {
    min-width: 80%;
    .form-text {
        margin: 10px 0;
        color: var(--baseTextColor);
    }

    .link {
        text-align: center;
        font-size: 14px;
        font-style: normal;

        a {
            text-decoration: none;
        }
    }

    .dx-button {
        background-color: var(--BaseColor) !important;
        color: var(--backgroundColor) !important;
        border-radius: 3%;
        .dx-icon {
            color: white;
        }
    }

    .dx-label {
        span {
            background-color: var(--backgroundColor);
            padding-left: 3px;
        }
    }

    .dx-field-item:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(.dx-first-row):not(.dx-label-v-align) {
        padding-top: 7px;
    }

    .dx-invalid-message.dx-overlay {
        padding-bottom: 5px;
    }
    .dx-invalid-message > .dx-overlay-content {
        padding-top: 4px;
    }
    .dx-layout-manager .dx-field-item {
        padding-bottom: 14px;
    }
}

body:has(.forgot-password-form) {
    .dx-field-button-item {
        text-align: center !important;
        .dx-button {
            width: 70% !important;
        }
    }
    .dx-card.content {
        min-height: unset !important;
        .header {
            flex-direction: column !important;
        }
        .login-link {
            text-align: center;
        }
    }
    .forgot-password-form {
        display: contents;
        width: 75%;
        align-items: center;
        position: relative;
    }
}

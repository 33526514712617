.single-card {
    // Desktop view
    @media only screen and (min-width: 500px) {
        .dx-card {
            width: 500px;
            min-height: 658.016px;
            margin: auto auto;
            padding: 40px;
            flex-grow: 0;
            background-color: var(--backgroundColor);
            border: 1px solid var(--cardBorderColor);
            border-radius: var(--cardCornerRadius);
            .screen-x-small & {
                width: 100%;
                height: 100%;
                border-radius: 0;
                box-shadow: none;
                margin: 0;
                border: 0;
                flex-grow: 1;
            }

            .header {
                margin-bottom: 30px;
                display: flex;
                flex-direction: column;

                .title {
                    color: var(--baseTextColor);
                    line-height: 28px;
                    font-weight: 500;
                    font-size: 20px;
                    width: -webkit-fill-available;
                    display: flex;
                    position: relative;
                    justify-content: center;
                    margin-bottom: inherit;
                }

                svg {
                    filter: var(--logoFilter);
                    width: var(--logoWidth);
                }

                .app-logo {
                    width: 100%;
                    height: 100%;
                    margin-bottom: 30px;
                    display: flex;
                    justify-content: center;
                }

                .description {
                    color: var(--baseTextColor);
                    line-height: 14px;
                    font-size: small;
                    margin-top: 20px;
                    margin-left: 45px;
                    margin-right: 45px;
                }
            }
        }
    }
    // Mobile view
    //TODO: Need to unify redundant declarations between mobile and desktop view
    @media only screen and (max-width: 500px) {
        .dx-card {
            width: 95%;
            height: auto;
            margin: auto auto;
            padding: 40px;
            flex-grow: 0;
            background-color: var(--backgroundColor);
            border: 1px solid var(--cardBorderColor);
            border-radius: var(--cardCornerRadius);
            .screen-x-small & {
                width: 100%;
                height: 100%;
                border-radius: 0;
                box-shadow: none;
                margin: 0;
                border: 0;
                flex-grow: 1;
            }

            .header {
                margin-bottom: 5px;
                display: flex;
                flex-direction: column;

                .title {
                    color: var(--baseTextColor);
                    line-height: 25px;
                    font-weight: 500;
                    font-size: 20px;
                    width: -webkit-fill-available;
                    display: flex;
                    position: relative;
                    justify-content: center;
                    margin-bottom: inherit;
                }

                svg {
                    filter: var(--logoFilter);
                    width: var(--logoWidth);
                }

                .app-logo {
                    width: 100%;
                    height: 100%;
                    margin-bottom: 30px;
                    display: flex;
                    justify-content: center;
                }

                .description {
                    color: var(--baseTextColor);
                    line-height: 14px;
                    font-size: small;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
